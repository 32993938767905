import { createRouter, createWebHashHistory } from 'vue-router'
import { Routes, AppScopes } from '../utils/enums'

import i18n from '@/i18n'
import store from '@/store'

import BaseView from '../views/global/Base.vue'

const { t } = i18n.global

const routes = [
  {
    scopes: [AppScopes.GLOBAL],
    path: Routes.HOME.PATH,
    name: Routes.HOME.NAME,
    component: BaseView,
    hidden: true,
    meta: {
      requiresAuth: true
    }
  },

  {
    scopes: [AppScopes.GLOBAL],
    path: Routes.LOGIN.PATH,
    name: Routes.LOGIN.NAME,
    component: () => import('@/views/global/Login/index'),
    hidden: true
  },

  {
    scopes: [AppScopes.ADMIN],
    redirect: Routes.ADMIN_DASHBOARD.PATH + '/index',
    path: Routes.ADMIN_DASHBOARD.PATH,
    component: BaseView,
    children: [
      {
        scopes: [AppScopes.ADMIN],
        path: 'index',
        name: Routes.ADMIN_DASHBOARD.NAME,
        meta: {
          requiresAuth: true,
          title: t('dashboard'),
          icon: 'chart-line'
        },
        component: () => import('@/views/admin/Dashboard')
      }
    ]
  },

  {
    scopes: [AppScopes.TEACHER],
    redirect: Routes.TEACHER_DASHBOARD.PATH + '/index',
    path: Routes.TEACHER_DASHBOARD.PATH,
    component: BaseView,
    children: [
      {
        scopes: [AppScopes.TEACHER],
        path: 'index',
        name: Routes.TEACHER_DASHBOARD.NAME,
        meta: {
          requiresAuth: true,
          title: t('dashboard'),
          icon: 'chart-line'
        },
        component: () => import('@/views/teacher/Dashboard')
      }
    ]
  },

  {
    scopes: [AppScopes.TEACHER],
    redirect: Routes.TEACHER_DASHBOARD.PATH,
    path: Routes.TEACHER_CLASS_LIST.PATH,
    component: BaseView,
    children: [
      {
        scopes: [AppScopes.TEACHER],
        path: Routes.TEACHER_CLASS_LIST.PATH + '/:class/course/:course',
        name: Routes.TEACHER_CLASS_LIST.NAME,
        meta: {
          requiresAuth: true,
          title: t('studentsList'),
        },
        component: () => import('@/views/teacher/List/Class.vue'),
        hidden: true
      },
      {
        scopes: [AppScopes.TEACHER],
        path: Routes.TEACHER_CLASS_LIST.PATH + '/:class/incumbent',
        name: 'Incumbent' + Routes.TEACHER_CLASS_LIST.NAME,
        meta: {
          requiresAuth: true,
          title: t('studentsList'),
        },
        component: () => import('@/views/teacher/List/IncumbentClass/'),
        hidden: true
      },
    ]
  },

  {
    scopes: [AppScopes.PARENT],
    redirect: Routes.PARENT_DASHBOARD.PATH + '/index',
    path: Routes.PARENT_DASHBOARD.PATH,
    component: BaseView,
    children: [
      {
        scopes: [AppScopes.PARENT],
        path: 'index',
        name: Routes.PARENT_DASHBOARD.NAME,
        meta: {
          requiresAuth: true,
          title: t('dashboard'),
          icon: 'chart-line'
        },
        component: () => import('@/views/parent/Dashboard')
      }
    ]
  },

  {
    scopes: [AppScopes.STUDENT],
    redirect: Routes.STUDENT_DASHBOARD.PATH + '/index',
    path: Routes.STUDENT_DASHBOARD.PATH,
    component: BaseView,
    children: [
      {
        scopes: [AppScopes.STUDENT],
        path: 'index',
        name: Routes.STUDENT_DASHBOARD.NAME,
        meta: {
          requiresAuth: true,
          title: t('dashboard'),
          icon: 'chart-line'
        },
        component: () => import('@/views/student/Dashboard')
      }
    ]
  },

  {
    scopes: [AppScopes.ADMIN],
    redirect: Routes.BASIC_INFO.PATH + '/schoolyears',
    path: Routes.BASIC_INFO.PATH,
    component: BaseView,
    alwaysShow: true,
    meta: {
      requiresAuth: true,
      title: t('basicInfo'),
      icon: 'info-circle'
    },
    children: [
      {
        scopes: [AppScopes.ADMIN],
        path: 'schoolyears',
        name: 'Schoolyears' + Routes.BASIC_INFO.NAME,
        meta: {
          requiresAuth: true,
          title: t('schoolyears'),
        },
        component: () => import('@/views/admin/BasicInfo/Schoolyears.vue')
      },
      {
        scopes: [AppScopes.ADMIN],
        path: 'establishments',
        name: 'Establishments' + Routes.BASIC_INFO.NAME,
        meta: {
          requiresAuth: true,
          title: t('establishments'),
        },
        component: () => import('@/views/admin/BasicInfo/Establishments.vue')
      },
      {
        scopes: [AppScopes.ADMIN],
        path: 'levels',
        name: 'Levels' + Routes.BASIC_INFO.NAME,
        meta: {
          requiresAuth: true,
          title: t('levels'),
        },
        component: () => import('@/views/admin/BasicInfo/Levels.vue')
      },
      {
        scopes: [AppScopes.ADMIN],
        path: 'staff',
        name: 'Staff' + Routes.BASIC_INFO.NAME,
        meta: {
          requiresAuth: true,
          title: t('adminStaff'),
        },
        component: () => import('@/views/admin/BasicInfo/Staff.vue')
      },
      {
        scopes: [AppScopes.ADMIN],
        path: 'departments',
        name: 'Departments' + Routes.BASIC_INFO.NAME,
        meta: {
          requiresAuth: true,
          title: t('departments'),
        },
        component: () => import('@/views/admin/BasicInfo/Departments.vue')
      },
      {
        scopes: [AppScopes.ADMIN],
        path: 'classes',
        name: 'Classes' + Routes.BASIC_INFO.NAME,
        meta: {
          requiresAuth: true,
          title: t('classes'),
        },
        component: () => import('@/views/admin/BasicInfo/Classes.vue')
      },
      {
        scopes: [AppScopes.ADMIN],
        path: 'courses',
        name: 'Courses' + Routes.BASIC_INFO.NAME,
        meta: {
          requiresAuth: true,
          title: t('courses'),
        },
        component: () => import('@/views/admin/BasicInfo/Courses.vue')
      },
      {
        scopes: [AppScopes.ADMIN],
        path: 'teachers',
        name: 'Teachers' + Routes.BASIC_INFO.NAME,
        meta: {
          requiresAuth: true,
          title: t('teachers'),
        },
        component: () => import('@/views/admin/BasicInfo/Teachers.vue')
      },
      {
        scopes: [AppScopes.ADMIN],
        path: 'parents',
        name: 'Parents' + Routes.BASIC_INFO.NAME,
        meta: {
          requiresAuth: true,
          title: t('parents'),
        },
        component: () => import('@/views/admin/BasicInfo/Parents.vue')
      },
      {
        scopes: [AppScopes.ADMIN],
        path: 'students',
        name: 'Students' + Routes.BASIC_INFO.NAME,
        meta: {
          requiresAuth: true,
          title: t('students'),
        },
        component: () => import('@/views/admin/BasicInfo/Students.vue')
      },
      {
        scopes: [AppScopes.ADMIN],
        path: 'students/:parent',
        name: 'ParentStudents' + Routes.BASIC_INFO.NAME,
        meta: {
          requiresAuth: true,
          title: t('students'),
        },
        component: () => import('@/views/admin/BasicInfo/Students.vue'),
        hidden: true
      },
      {
        scopes: [AppScopes.ADMIN],
        path: 'evaluation-types',
        name: 'EvaluationTypes' + Routes.BASIC_INFO.NAME,
        meta: {
          requiresAuth: true,
          title: t('evaluationTypes'),
        },
        component: () => import('@/views/admin/BasicInfo/EvaluationTypes.vue')
      },
      {
        scopes: [AppScopes.ADMIN],
        path: 'activities',
        name: 'Activities' + Routes.BASIC_INFO.NAME,
        meta: {
          requiresAuth: true,
          title: t('activities'),
        },
        component: () => import('@/views/admin/BasicInfo/Activities.vue')
      },
      {
        scopes: [AppScopes.ADMIN],
        path: 'course-sections',
        name: 'CourseSections' + Routes.BASIC_INFO.NAME,
        meta: {
          requiresAuth: true,
          title: t('courseSections'),
        },
        component: () => import('@/views/admin/BasicInfo/CourseSections.vue')
      }
    ]
  },

  {
    scopes: [AppScopes.ADMIN],
    redirect: Routes.YEAR_PLANNING.PATH + '/repartition',
    path: Routes.YEAR_PLANNING.PATH,
    component: BaseView,
    alwaysShow: true,
    meta: {
      requiresAuth: true,
      title: t('yearPlanning'),
      icon: 'calendar-alt'
    },
    children: [
      {
        scopes: [AppScopes.ADMIN],
        path: 'repartition',
        name: 'Repartition' + Routes.YEAR_PLANNING.NAME,
        meta: {
          requiresAuth: true,
          title: t('coursesRepartition'),
        },
        component: () => import('@/views/admin/YearPlanning/CoursesRepartition')
      },
      {
        scopes: [AppScopes.ADMIN],
        path: 'choice-courses',
        name: 'ChoiceCourses' + Routes.YEAR_PLANNING.NAME,
        meta: {
          requiresAuth: true,
          title: t('choiceCoursesManagement'),
        },
        component: () => import('@/views/admin/YearPlanning/ChoiceCourses')
      },
      {
        scopes: [AppScopes.ADMIN],
        path: 'cutting',
        name: 'Cutting' + Routes.YEAR_PLANNING.NAME,
        meta: {
          requiresAuth: true,
          title: t('yearCutting'),
        },
        component: () => import('@/views/admin/YearPlanning/YearCutting')
      },
    ]
  },

  {
    scopes: [AppScopes.ADMIN],
    redirect: Routes.REPORT_MANAGE.PATH + '/index',
    path: Routes.REPORT_MANAGE.PATH,
    component: BaseView,
    children: [
      {
        scopes: [AppScopes.ADMIN],
        path: 'index',
        name: Routes.REPORT_MANAGE.NAME,
        meta: {
          requiresAuth: true,
          title: t('reportManage'),
          icon: 'tasks'
        },
        component: () => import('@/views/admin/ReportManage')
      },
      {
        scopes: [AppScopes.ADMIN],
        path: 'class/:class/student/:student/marks',
        name: 'Marks' + Routes.REPORT_MANAGE.NAME,
        meta: {
          requiresAuth: true,
          title: t('reportManage'),
        },
        hidden: true,
        component: () => import('@/views/admin/ReportManage/ViewMarks.vue')
      }
    ]
  },

  {
    scopes: [AppScopes.GLOBAL],
    redirect: Routes.SETTINGS.PATH + '/account',
    path: Routes.SETTINGS.PATH,
    component: BaseView,
    alwaysShow: true,
    meta: {
      requiresAuth: true,
      title: t('settings'),
      icon: 'tools'
    },
    children: [
      {
        scopes: [AppScopes.GLOBAL],
        path: 'account',
        name: 'Account' + Routes.SETTINGS.NAME,
        meta: {
          requiresAuth: true,
          title: t('accountSettings'),
        },
        component: () => import('@/views/global/Settings/AccountSettings.vue')
      },
      {
        scopes: [AppScopes.ADMIN],
        path: 'app',
        name: 'App' + Routes.SETTINGS.NAME,
        meta: {
          requiresAuth: true,
          title: t('appSettings'),
        },
        component: () => import('@/views/admin/Settings/AppSettings.vue')
      },
      {
        scopes: [AppScopes.ADMIN],
        path: 'admin',
        name: 'Admin' + Routes.SETTINGS.NAME,
        meta: {
          requiresAuth: true,
          title: t('administrators'),
        },
        component: () => import('@/views/admin/Settings/Administrators.vue')
      },
      {
        scopes: [AppScopes.ADMIN],
        path: 'backups',
        name: 'Backups' + Routes.SETTINGS.NAME,
        meta: {
          requiresAuth: true,
          title: t('backups'),
        },
        component: () => import('@/views/admin/Settings/Backups.vue')
      }
    ]
  },

  {
    scopes: [AppScopes.GLOBAL],
    path: Routes.NOT_FOUND.PATH,
    name: Routes.NOT_FOUND.NAME,
    component: () => import('@/views/global/NotFound.vue'),
    hidden: true
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {

    if (savedPosition) {

      return savedPosition

    } else {

        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve({ left: 0, top: 0, behavior: 'smooth' })
          }, 250)
        })

    }

  },
})

router.beforeEach((to, from, next) => {

  store.commit('globals/updatePreviousRouteName', from.name, { root: true })
  store.commit('globals/updateNextRouteName', to.name, { root: true })

  store.commit('session/updateSession', { root: true })

  if (to.matched.some(record => record.meta.requiresAuth)) {

    if (store.getters['session/isUserConnected']) next()
    else next({ path: Routes.LOGIN.PATH })

  } else {

    next()

  }

})

export default router
