import { mapState, mapGetters, mapMutations } from 'vuex'

import { Settings, VarTypes } from '@/utils/enums'

export default{
    computed: {
        ...mapState({
            userData: state => state.session.userData,
            defaultYearCutting: state => state.globals.defaultYearCutting            
        }),        
        ...mapGetters({
            getSettingValue: 'settings/getSettingValue'
        }),
        adminSchoolyear() {

            return this.getSettingValue(Settings.ADMIN_SCHOOLYEAR.NAME)

        },
        currentSchoolyear() {

            return this.getSettingValue(Settings.CURRENT_SCHOOLYEAR.NAME)

        },
        lockedData() {

            return this.getSettingValue(Settings.LOCKED_DATA.NAME, VarTypes.BOOLEAN)
    
        },        
        userRole() {

            if (this.userData) return parseInt(this.userData.role)
            else return ''

        }
    },
    methods: {
        ...mapMutations({
            updateDefaultYearCutting: 'globals/updateDefaultYearCutting'
        })
    }
}