<template>
  <i v-if="icon && icon.includes('el-icon')" :class="icon + ' sub-el-icon'" />
  <font-awesome-icon v-else-if="icon" :icon="icon" class="sub-el-icon" size="1x"/>
</template>

<script>
export default {
  name: 'MenuItemIcon',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.sub-el-icon {
  color: currentColor;
  width: 1em;
  height: 1em;
}

.fa-icon {
  font-size: 18px !important;
}
</style>
