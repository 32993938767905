import i18n from '@/i18n'

const { t } = i18n.global

export const Routes = {
    HOME: {
        NAME: 'Home',
        PATH: '/',
        ALIAS: '/home'
    },
    NOT_FOUND: {
        NAME: '404',
        PATH: '/:pathMatch(.*)*'
    },
    LOGIN: {
        NAME: 'Login',
        PATH: '/login'
    },
    SETTINGS: {
        NAME: 'Settings',
        PATH: '/settings'
    },
    PARENT_DASHBOARD: {
        NAME: 'ParentDashboard',
        PATH: '/parent/dashboard'
    },
    STUDENT_DASHBOARD: {
        NAME: 'StudentDashboard',
        PATH: '/student/dashboard'
    },
    TEACHER_DASHBOARD: {
        NAME: 'TeacherDashboard',
        PATH: '/teacher/dashboard'
    },
    ADMIN_DASHBOARD: {
        NAME: 'AdminDashboard',
        PATH: '/admin/dashboard'
    },    
    BASIC_INFO: {
        NAME: 'BasicInfo',
        PATH: '/basic-info'
    },    
    YEAR_PLANNING: {
        NAME: 'YearPlanning',
        PATH: '/year-planning'
    },
    REPORT_MANAGE: {
        NAME: 'ReportManage',
        PATH: '/report-manage'
    },
    TEACHER_CLASS_LIST: {
        NAME: 'TeacherClassList',
        PATH: '/teacher/class'
    }
}

export const VarTypes = {
    ARRAY: 'array',
    NUMBER: 'number',
    OBJECT: 'object',
    STRING: 'string',
    SYMBOL: 'symbol',
    BIGINT: 'bigint',
    BOOLEAN: 'boolean',
    FUNCTION: 'function',    
    UNDEFINED: 'undefined'
}

export const AppScopes = {
    GLOBAL: 'global',
    ADMIN: 'admin',
    TEACHER: 'teacher',
    PARENT: 'parent',
    STUDENT: 'student'
}

export const Roles = {
    SUPERADMIN: 0,
    ADMIN: 1,
    TEACHER: 2,
    STUDENT: 3,
    PARENT: 4    
}

export const Settings = {
    SCHOOLYEAR_CHANGE_MODE: {
        NAME: 'SCHOOLYEAR_CHANGE_MODE',
        DEFAULT: 'auto'
    },
    CURRENT_SCHOOLYEAR: {
        NAME: 'CURRENT_SCHOOLYEAR',
        DEFAULT: ''
    },
    ADMIN_SCHOOLYEAR: {
        NAME: 'ADMIN_SCHOOLYEAR',
        DEFAULT: ''
    },
    LOCKED_DATA: {
        NAME: 'LOCKED_DATA',
        DEFAULT: 0
    }
}

export const SchoolData = {
    NAME: {
        NAME: 'NAME',
        DEFAULT: ''
    },
    DESCRIPTION: {
        NAME: 'DESCRIPTION',
        DEFAULT: ''
    },
    ADDRESS: {
        NAME: 'ADDRESS',
        DEFAULT: ''
    },
    PHONE: {
        NAME: 'PHONE',
        DEFAULT: ''
    },
    EMAIL: {
        NAME: 'EMAIL',
        DEFAULT: ''
    },
    POSTAL_BANK: {
        NAME: 'POSTAL_BANK',
        DEFAULT: ''
    },
    WEBSITE: {
        NAME: 'WEBSITE',
        DEFAULT: ''
    },
    LOGO: {
        NAME: 'LOGO',
        DEFAULT: ''
    },
    YEAR_CUTTING_TYPE: {
        NAME: 'YEAR_CUTTING_TYPE',
        DEFAULT: '2'
    }
}

export const LevelTypes = {
    PRESCOLAIRE: {
        id: 1,
        label: t('preschool')
    },
    PRIMAIRE: {
        id: 2,
        label: t('primary')
    },
    COLLEGE: {
        id: 5,
        label: t('college')
    },
    LYCEE: {
        id: 3,
        label: t('highschool')
    },
    UNIVERSITE: {
        id: 4,
        label: t('university')
    }
}

export const Titles = {
    MISTER: {
        id: 1,
        label: t('mister')
    },
    MISS: {
        id: 2,
        label: t('miss')
    },
    MISTER_MISS: {
        id: 3,
        label: t('misterAndMiss')
    }
}

export const Sexes = {
    MALE: {
        id: 1,
        label: t('male')
    },
    FEMALE: {
        id: 2,
        label: t('female')
    }
}

export const Features = {
    EXPLORATION: {
        id: 1,
        label: t('explorationCourses')
    },
    SPECIALITY_1: {
        id: 2,
        label: t('firstSpecialityCourses')
    },
    SPECIALITY_2: {
        id: 3,
        label: t('secondSpecialityCourses')
    },
    LIVING_LANGUAGE: {
        id: 4,
        label: t('livingLanguages')
    }
}

export const YearCuttingTypes = {
    SEMESTER: {
        id: 1,
        label: t('semester')
    },
    TERM: {
        id: 2,
        label: t('term')
    }
}

export const YearTerms = {
    FIRST_TERM: {
        id: 1,
        label: t('firstTerm')
    },
    SECOND_TERM: {
        id: 2,
        label: t('secondTerm')
    },
    THIRD_TERM: {
        id: 3,
        label: t('thirdTerm')
    }
}

export const YearSemesters = {
    FIRST_SEMESTER: {
        id: 1,
        label: t('firstSemester')
    },
    SECOND_SEMESTER: {
        id: 2,
        label: t('secondSemester')
    }
}

export const EvaluationTypes = {    
    ASSIGNMENT: {
        id: 1,
        label: t('assignment')
    },
    EVALUATION: {
        id: 2,
        label: t('evaluation')
    },
    COMPOSITION: {
        id: 3,
        label: t('composition')
    },
    MOCKEXAM: {
        id: 4,
        label: t('mockExam')
    },
    TEST: {
        id: 5,
        label: t('test')
    }
}

export const StaffRoles = {
    PRINCIPAL: {
        id: 1,
        label: t('principal')
    },
    DIRECTOR: {
        id: 2,
        label: t('director')
    },
    SCHOOL_LIFE_MANAGER: {
        id: 3,
        label: t('schoolLifeManager')
    }
}

export const ActivityTypes = {
    EXTRACURRICULAR: {
        id: 1,
        label: t('extracurricularActivities')
    },
    RECREATIONAL: {
        id: 2,
        label: t('recreationalActivities')
    },
    PARENTING: {
        id: 3,
        label: t('parentingActivities')
    }
}