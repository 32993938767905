import { VueCookieNext } from 'vue-cookie-next'

const state = {
  sidebar: {
    opened: VueCookieNext.getCookie('sidebarStatus') ? !!+VueCookieNext.getCookie('sidebarStatus') : true,
    withoutAnimation: false
  },
  device: 'desktop',
  showSettings: false,
  fixedHeader: true,
  sidebarLogo: true
}

const getters = {

}

const mutations = {
  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened = !state.sidebar.opened
    state.sidebar.withoutAnimation = false
    if (state.sidebar.opened) {      
      VueCookieNext.setCookie('sidebarStatus', 1, { expire: Infinity })
    } else {
      VueCookieNext.setCookie('sidebarStatus', 0, { expire: Infinity })      
    }
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    VueCookieNext.setCookie('sidebarStatus', 0, { expire: Infinity })
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  },

  setSidebarLogoTo(state, show){

    state.sidebarLogo = show

  },
  setShowSettingsTo(state, show){

    state.showSettings = show

  },
  setFixedHeaderTo(state, fix){

    state.fixedHeader = fix

  }
}

const actions = {
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation)
  },
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
