import { v4 as uuidv4 } from 'uuid'

import { getCurrentTimestamp } from '@/utils/chronos'

const SESSION_LIFETIME  = 1260

const state = () => ({
    sessionId: null,
    sessionDate: null,
    loadingSessionId: null,

    userData: null,
    connected: false,
    rememberMe: false
})

const getters = {
    isUserConnected(state){

        return state.userData && state.connected

    },
    isSessionAlive(state){

        return state.sessionId && state.sessionDate

    }
}

const mutations = {    
    startSession(state){

        if (state.sessionId === null || state.sessionDate === null){

            this.commit('session/setSessionId', { root: true })
            this.commit('session/resetSessionData', { root: true })

        }else{

            this.commit('session/updateSession', { root: true })

        }    

    },
    updateSession(state){

        if (getCurrentTimestamp() - state.sessionDate > SESSION_LIFETIME && !state.rememberMe) {
                
            this.commit('session/setSessionId', { root: true })
            this.commit('session/resetSessionData', { root: true })

        }else{

            state.sessionDate = getCurrentTimestamp()

        }

    },
    destroySession(state){
        
        state.sessionId = null
        state.sessionDate = null
        this.commit('session/resetSessionData', { root: true })

    },

    resetSessionData(state){

        state.userData = null
        state.connected = false
        state.rememberMe = false

    },
    setSessionId(state){

        state.sessionId = uuidv4()
        state.sessionDate = getCurrentTimestamp()

    },
    setLoadingSessionId(state){

        state.loadingSessionId = uuidv4()

    },

    updateUserData(state, data){
        
        state.userData = data

    },
    updateConnected(state, c){

        state.connected = c

    },
    updateRememberMe(state, r){

        state.rememberMe = r

    }
}

const actions = {

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}