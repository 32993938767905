import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'

import _ from 'lodash'
import i18n from './i18n'
import axios from 'axios'
import VueAxios from 'vue-axios'
import countTo from 'vue3-count-to'
import ElementPlus from 'element-plus'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import { VueCookieNext } from 'vue-cookie-next'

import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'

import globalMixin from './mixins/global'

import 'normalize.css/normalize.css'
import './assets/styles/element-plus/variables.scss'

library.add(fas, fab)

const app = createApp(App)
                .use(i18n)
                .use(store)
                .use(router)
                .use(countTo)
                .use(ElementPlus)
                .use(VueCookieNext)
                .use(VueAxios, axios)
                .component('font-awesome-layers-text', FontAwesomeLayersText)
                .component('font-awesome-layers', FontAwesomeLayers)
                .component('font-awesome-icon', FontAwesomeIcon)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.config.globalProperties.$_ = _

app.mixin(globalMixin)

app.mount('#app')
