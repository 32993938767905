import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate"

import mirage from './modules/mirage'
import globals from './modules/globals'
import session from './modules/session'
import template from './modules/template'

import user from './modules/database/user'
import data from './modules/database/data'
import staff from './modules/database/staff'
import admin from './modules/database/admin'
import levels from './modules/database/levels'
import classes from './modules/database/classes'
import courses from './modules/database/courses'
import parents from './modules/database/parents'
import students from './modules/database/students'
import teachers from './modules/database/teachers'
import settings from './modules/database/settings'
import activities from './modules/database/activities'
import schooldata from './modules/database/schooldata'
import schoolyears from './modules/database/schoolyears'
import departments from './modules/database/departments'
import evaluations from './modules/database/evaluations'
import repartition from './modules/database/repartition'
import yearcuttings from './modules/database/yearcuttings'
import choicecourses from './modules/database/choicecourses'
import coursesections from './modules/database/coursesections'
import establishments from './modules/database/establishments'
import authentication from './modules/database/authentication'
import evaluationtypes from './modules/database/evaluationtypes'
import evaluationnotes from './modules/database/evaluationnotes'
import evaluationtexts from './modules/database/evaluationtexts'
import chosenactivities from './modules/database/chosenactivities'
import evaluationverdict from './modules/database/evaluationverdict'

export default createStore({  
  plugins: [createPersistedState()],
  modules: {
    user,
    data,
    staff,
    admin,
    levels,    
    mirage,
    classes,
    courses,
    parents,
    globals,
    session,
    students,
    template,
    settings,
    teachers,
    activities,
    schooldata,
    schoolyears,    
    departments,
    repartition,
    evaluations,
    yearcuttings,    
    choicecourses,
    coursesections,
    establishments,
    authentication,
    evaluationtypes,
    evaluationnotes,
    evaluationtexts,
    chosenactivities,
    evaluationverdict
  }
})
