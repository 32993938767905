<template>
  <div class="navbar">

    <hamburger :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />

    <breadcrumb class="breadcrumb-container" />

    <div class="right-menu">

      <template v-if="device !== 'mobile'">

          <template v-if="userRole == Roles.SUPERADMIN || userRole == Roles.ADMIN">

              <router-link :to="Routes.BASIC_INFO.PATH + '/schoolyears'" class="define-schoolyear-wrapper mr-10" v-if="schoolyears && schoolyears.length === 0">
                  {{ $t('addSchoolyear') }}
              </router-link>

              <template v-else>

                  <span class="current-session-wrapper">
                    <b>{{ $t('session') }}&nbsp;</b> {{ ' ' + currentSession }}
                  </span>

                  <el-divider direction="vertical"></el-divider>

                  <template v-if="currentSchoolyear && adminSchoolyear">

                      <span class="current-schoolyear-wrapper mr-10" v-if="currentSchoolyear == adminSchoolyear">
                        <b>{{ $t('schoolyear') }}</b> {{ ' ' + currentSchoolyearCode }}
                      </span>
                      <template v-else>

                        <span class="current-schoolyear-wrapper">
                          <b>{{ $t('currentYear') }}</b> {{ ' ' + currentSchoolyearCode }}
                        </span>

                        <el-divider direction="vertical"></el-divider>

                        <span class="current-schoolyear-wrapper mr-10">
                          <b>{{ $t('consultedYear') }}</b> {{ ' ' + adminSchoolyearCode }}
                        </span>

                      </template>
                  </template>

                  <router-link :to="Routes.SETTINGS.PATH + '/app'" class="define-schoolyear-wrapper mr-10" v-else>
                      {{ $t('defineSchoolyear') }}
                  </router-link>

              </template>

          </template>

          <template v-else>

              <span class="current-session-wrapper">
                  <b>{{ $t('session') }}&nbsp;</b> {{ ' ' + currentSession }}
              </span>

              <el-divider direction="vertical"></el-divider>

              <span class="current-schoolyear-wrapper" v-if="userData">
                  {{ userData.name }}
              </span>

              <el-divider direction="vertical"></el-divider>

              <span class="current-schoolyear-wrapper mr-10">
                  <b>{{ $t('schoolyear') }}&nbsp;</b> {{ ' ' + currentSchoolyearCode }}
              </span>

          </template>

      </template>

      <el-dropdown class="avatar-container ml-3 mr-3" trigger="click">
        <template v-slot:default v-if="userData">
          <div class="avatar-wrapper">
            <el-avatar :src="userData.avatar" v-if="userData.avatar"></el-avatar>
            <el-avatar v-else> {{ getNameInitials(userData.name || '') }} </el-avatar>
            <el-icon class="ml-2" style="cursor: pointer">
              <CaretBottom/>
            </el-icon>
          </div>
        </template>
        <template v-slot:dropdown>
          <el-dropdown-menu class="user-dropdown">

            <el-dropdown-item class="dropdown-menu-item">
              <router-link :to="Routes.SETTINGS.PATH + '/account'">
                {{ $t('accountSettings') }}
              </router-link>
            </el-dropdown-item>

            <el-dropdown-item class="dropdown-menu-item" @click="logout()">
              {{ $t('logout') }}
            </el-dropdown-item>

          </el-dropdown-menu>
        </template>
      </el-dropdown>

    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

import { Routes, Settings, Roles } from '@/utils/enums'
import { getNameInitials } from "@/utils/functions"

import Hamburger from './Hamburger'
import Breadcrumb from './Breadcrumb'

export default {
  components: {
    Breadcrumb,
    Hamburger
  },
  data() {
    return {
      Roles,
      Routes,
      Settings,
      adminSchoolyearCode: '',
      currentSchoolyearCode: ''
    }
  },
  watch: {
      settings: {
          handler(val, oldVal) {
              this.loadSchoolyearCode()
          }
      },
      schoolyears: {
          handler(val, oldVal) {
              this.loadSchoolyearCode()
          },
          immediate: true
      }
  },
  computed: {
    ...mapState({
       device: state => state.template.device,
       sidebar: state => state.template.sidebar,
       userData: state => state.session.userData,
       settings: state => state.settings.settings,
       schoolyears: state => state.schoolyears.schoolyears
    }),
    currentSession(){

      let session = ''

      switch (this.userRole) {
        case Roles.SUPERADMIN:
          session = this.$t('administrator')
          break

        case Roles.ADMIN:
          session = this.$t('administrator')
          break

        case Roles.TEACHER:
          session = this.$t('teacher')
          break

        case Roles.PARENT:
          session = this.$t('parent')
          break

        case Roles.STUDENT:
          session = this.$t('student')
          break
      }

      return session

    }
  },
  methods: {
    ...mapMutations({
      destroySession: 'session/destroySession'
    }),
    ...mapActions({
      fetchSettingsDB: 'settings/fetchSettingsDB',
    }),
    getNameInitials,
    toggleSideBar() {
      this.$store.dispatch('template/toggleSideBar')
    },
    logout() {

      this.$confirm(this.$t('logoutConfirmMessage'), this.$t('logout'), {
          confirmButtonText: this.$t('yes'),
          cancelButtonText: this.$t('cancel'),
          type: 'warning',
      })
      .then(async () => {

          this.destroySession()

          this.$router.replace(Routes.LOGIN.PATH)

      })
      .catch(() => {

      })

    },
    async loadSchoolyearCode(){

      if (this.currentSchoolyear) {

        let schoolyear = this.schoolyears.find(s => s.id == this.currentSchoolyear)

        this.currentSchoolyearCode = schoolyear ? schoolyear.code : ''

      }

      if (this.adminSchoolyear) {

        let schoolyear = this.schoolyears.find(s => s.id == this.adminSchoolyear)

        this.adminSchoolyearCode = schoolyear ? schoolyear.code : ''

      }

    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~@/assets/styles/template/index.scss";

  .navbar {
    height: 50px;
    overflow: hidden;
    position: relative;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0,21,41,.08);

    .hamburger-container {
      line-height: 46px;
      height: 100%;
      float: left;
      cursor: pointer;
      transition: background .3s;
      -webkit-tap-highlight-color:transparent;

      &:hover {
        background: rgba(0, 0, 0, .025)
      }
    }

    .breadcrumb-container {
      float: left;
    }

    .right-menu {
      float: right;
      height: 100%;

      &:focus {
        outline: none;
      }

      :deep(.avatar-container) {
        vertical-align: middle;
        display: inline-block;
        line-height: unset;
        height: 100%;

        .avatar-wrapper {
          height: 100%;
          display: flex;
          align-items: center;
          margin-right: 15px;

          .avatar {
            cursor: pointer;
            align-self: center;
            display: flex !important;
          }

        }

      }
    }
  }
</style>

<style scoped>
  .navbar-btn {
    width: 45px;
    height: 100%;
    vertical-align: middle;
    display: inline-block;
    text-align: center;
    background: none;
    cursor: pointer;
    border: none;
    padding: 0;
  }

  .navbar-btn:hover {
    background-color: whitesmoke;
  }

  .navbar-preview-btn {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .avatar-wrapper {
    cursor: pointer;
  }

  ::v-deep(.schoolyear-dropdown){
    cursor: pointer;
    margin-right: 50px;
  }

  ::v-deep(.dropdown-menu-item:hover) {
    background-color: #fddfbc;
    color: dimgray;
  }

  .current-schoolyear-wrapper {
    color: #97a8be;
    font-size: 14px;
    cursor: text;
  }

  .current-session-wrapper {
    color: #97a8be;
    font-size: 14px;
    cursor: text;
  }

  .define-schoolyear-wrapper{
    background-color: var(--primary-color);
    padding: 8px 5px 8px 5px;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
    color: white;
    height: 100%;
  }

  .define-schoolyear-wrapper:hover,
  .define-schoolyear-wrapper:focus,
  .define-schoolyear-wrapper:active{
    color: whitesmoke;
  }
</style>
