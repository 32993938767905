const state = () => ({
    levels: [
        
    ],
    classes: [
        
    ],
    courses: [
        
    ],
    parents: [
        
    ],
    teachers: [
        
    ],
    students: [
        
    ],
    plans: [
        
    ],
    selectedStudent: null
})

const getters = {
    
}

const mutations = {
    setSelectedStudent(state, s){

        state.selectedStudent = s
        
    },    
}

const actions = {

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}