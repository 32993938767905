import axios from 'axios'

import { DATABASE_API_BASE_URL, DATABASE_API_KEY } from '@/utils/constants'

const CONTEXT = 'choicecourses'

const state = () => ({
    
})

const getters = {
    
}

const mutations = {
    
}

const actions = {
    async fetchChoiceCoursesDB({}, payload){
        
        let success = false

        let response = null
        let error = null

        try {
            
            response = await axios.post(DATABASE_API_BASE_URL, {
                key: DATABASE_API_KEY,
                context: CONTEXT,
                action: payload.action,
                params: payload.params || null                
            })

            success = response && response.data !== ''

        } catch (err) {

            error = err

            success = false

        }
        
        return {            
            success: success,
            failure: !success,
            data: response ? response.data : null,
            error: error
        }

    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}