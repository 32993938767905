<template>
  <router-view/>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'

export default {
  created() {

    this.setLoadingSessionId()
    this.startSession()

    this.initSettings()
    this.initSchoolData()
    this.loadSchoolyears()

  },
  methods: {
    ...mapMutations({
      startSession: 'session/startSession',
      setLoadingSessionId: 'session/setLoadingSessionId'
    }),
    ...mapActions({
      initSettings: 'settings/initSettingsDB',
      initSchoolData: 'schooldata/initSchoolDataDB',
      loadSchoolyears: 'schoolyears/loadSchoolyears'
    })
  }
}
</script>

<style src="@/assets/styles/app.css"></style>
