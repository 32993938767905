import axios from 'axios'

import { DATABASE_API_BASE_URL, DATABASE_API_KEY } from '@/utils/constants'
import { differenceWithToday } from '@/utils/chronos'
import { Settings } from '@/utils/enums'

const CONTEXT = 'schoolyears'

const state = () => ({
    schoolyears: []
})

const getters = {
    
}

const mutations = {
    
}

const actions = {
    async checkSchoolyearsData({ dispatch, state }){

        for (let i = 0; i < state.schoolyears.length; i++) {

            let task = await dispatch('fetchSchoolyearsDB', { 
                action: 'countSchoolyearData', 
                params: {
                    schoolyear: state.schoolyears[i].id                    
                }
            })            

            state.schoolyears[i].has_data = task.data

        }        

    },
    async loadSchoolyears({ dispatch, state, rootGetters }){

        let task = await dispatch('fetchSchoolyearsDB', { action: 'getSchoolyears' })

        if (task.success && task.data) {
            
            state.schoolyears = task.data

            if (state.schoolyears && state.schoolyears.length === 0){

                await dispatch('settings/fetchSettingsDB', { 
                    action: 'updateSetting',
                    params: {
                        name: Settings.CURRENT_SCHOOLYEAR.NAME,
                        value: Settings.CURRENT_SCHOOLYEAR.DEFAULT
                    }
                }, { root: true })

                await dispatch('settings/fetchSettingsDB', { 
                    action: 'updateSetting',
                    params: {
                        name: Settings.ADMIN_SCHOOLYEAR.NAME,
                        value: Settings.ADMIN_SCHOOLYEAR.DEFAULT
                    }
                }, { root: true })

            }else{

                if (rootGetters['settings/getSettingValue'](Settings.SCHOOLYEAR_CHANGE_MODE.NAME) === 'auto'){
                    
                    let selectedSchoolyear = null

                    state.schoolyears.reverse()

                    state.schoolyears.every(schoolyear => {                        
                                                
                        if (differenceWithToday(schoolyear.start_date) <= 0 
                            && differenceWithToday(schoolyear.end_date) >= 0){                            

                            selectedSchoolyear = schoolyear
                            
                            return false

                        }

                        selectedSchoolyear = schoolyear

                        return true

                    })

                    if (selectedSchoolyear) {
                        
                        await dispatch('settings/fetchSettingsDB', { 
                            action: 'updateSetting',
                            params: {
                                name: Settings.CURRENT_SCHOOLYEAR.NAME,
                                value: selectedSchoolyear.id
                            }
                        }, { root: true })

                        if (!rootGetters['settings/getSettingValue'](Settings.ADMIN_SCHOOLYEAR.NAME)){

                            await dispatch('settings/fetchSettingsDB', { 
                                action: 'updateSetting',
                                params: {
                                    name: Settings.ADMIN_SCHOOLYEAR.NAME,
                                    value: selectedSchoolyear.id
                                }
                            }, { root: true })

                        }

                    }

                    state.schoolyears.reverse()

                }

            }

        }

        await dispatch('checkSchoolyearsData')

    },
    async fetchSchoolyearsDB({}, payload){
        
        let success = false

        let response = null
        let error = null

        try {
            
            response = await axios.post(DATABASE_API_BASE_URL, {
                key: DATABASE_API_KEY,
                context: CONTEXT,
                action: payload.action,
                params: payload.params || null                
            })

            success = response && response.data !== ''

        } catch (err) {

            error = err

            success = false

        }
        
        return {            
            success: success,
            failure: !success,
            data: response ? response.data : null,
            error: error
        }

    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}