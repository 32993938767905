<template>

    <div :class="classObj" class="app-wrapper">

        <div v-if="device==='mobile'&&sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
        <sidebar class="sidebar-container" />

        <div class="main-container">
        <div :class="{'fixed-header':fixedHeader}">
            <navbar />
        </div>
        <app-main :style="{ marginTop : '15px' }" />
        </div>

    </div>

</template>

<script>
import { mapState } from 'vuex'

import { Routes, Roles } from '@/utils/enums'
import { Navbar, Sidebar, AppMain } from '@/components/sections/template/'

import ResizeMixin from '@/mixins/template/ResizeHandler'

export default {
  name: 'Base',
  components: {
    Navbar,
    Sidebar,
    AppMain
  },
  mixins: [ResizeMixin],
  created () {

    if (this.$route.name === Routes.HOME.NAME){
      if (this.userData){

        switch (parseInt(this.userData.role)) {
          case Roles.SUPERADMIN:
            this.$router.replace(Routes.ADMIN_DASHBOARD.PATH)
            break

          case Roles.ADMIN:
            this.$router.replace(Routes.ADMIN_DASHBOARD.PATH)
            break

          case Roles.TEACHER:
            this.$router.replace(Routes.TEACHER_DASHBOARD.PATH)
            break

          case Roles.STUDENT:
            this.$router.replace(Routes.STUDENT_DASHBOARD.PATH)
            break

          case Roles.PARENT:
            this.$router.replace(Routes.PARENT_DASHBOARD.PATH)
            break
        }

      }else{
        this.$router.replace(Routes.LOGIN.PATH)
      }
    }

  },
  computed: {
    ...mapState({
        device: state => state.template.device,
        sidebar: state => state.template.sidebar,
        userData: state => state.session.userData,
        fixedHeader: state => state.template.fixedHeader,
    }),
    classObj() {
      return {
        withoutAnimation: this.sidebar.withoutAnimation,
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        mobile: this.device === 'mobile'
      }
    }
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch('template/closeSideBar', { withoutAnimation: false })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~@/assets/styles/template/index.scss";

  .app-wrapper {
    @include clearfix;
    position: relative;
    height: 100%;
    width: 100%;
    &.mobile.openSidebar{
      position: fixed;
      top: 0;
    }
  }
  .drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }

  .fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    width: calc(100% - #{$sideBarWidth});
    transition: width 0.28s;
  }

  .hideSidebar .fixed-header {
    width: calc(100% - 54px)
  }

  .mobile .fixed-header {
    width: 100%;
  }
</style>
