<template>
  <div style="padding: 0 15px;" @click="toggleClick">
    <font-awesome-icon
        class="hamburger"
        :icon="isActive ? 'outdent' : 'indent'"
        :style="{ color: '#9e7d33' }"/>
  </div>
</template>

<script>
export default {
  name: 'Hamburger',
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  emits: ['toggleClick'],
  methods: {
    toggleClick() {
      this.$emit('toggleClick')
    }
  }
}
</script>

<style scoped>
.hamburger {
  display: inline-block;
  vertical-align: middle;
  height: 20px;
  width: 20px;
}
</style>
