<template>
  <div :class="{ 'has-logo' : showLogo }">

    <logo v-if="showLogo" :collapse="isCollapse" />

    <el-scrollbar wrap-class="scrollbar-wrapper">

      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="true"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        mode="vertical">

        <sidebar-item v-for="route in routes" :key="route.path" :item="route" :base-path="route.path" />

      </el-menu>

    </el-scrollbar>

  </div>
</template>

<script>
import { mapState } from 'vuex'

import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/assets/styles/template/variables.scss'

import { AppScopes } from '@/utils/enums'

export default {
  components: {
    SidebarItem,
    Logo
  },
  computed: {
    ...mapState({
      sidebar: state => state.template.sidebar,
      currentScope: state => state.globals.currentAppScope
    }),
    routes() {

      let filteredRoutes = []
      let routes = this.$router.options.routes.filter(route => route.scopes.includes(AppScopes.GLOBAL) || route.scopes.includes(this.currentScope))

      routes.forEach(route => {

        let tempRoute = this.$_.cloneDeep(route)

        if (tempRoute.children && tempRoute.children.length){

            tempRoute.children = tempRoute.children.filter(r => r.scopes.includes(AppScopes.GLOBAL) || r.scopes.includes(this.currentScope))

        }

        filteredRoutes.push(tempRoute)

      })

      return filteredRoutes

    },
    activeMenu() {
      const route = this.$route
      const { meta, path } = route

      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    showLogo() {

      return this.$store.state.template.sidebarLogo

    },
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebar.opened
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~@/assets/styles/template/index.scss";
</style>