import axios from 'axios'

import { DATABASE_API_BASE_URL, DATABASE_API_KEY } from '@/utils/constants'

import { Settings } from '@/utils/enums'

const CONTEXT = 'parents'

const state = () => ({
    cacheParents: [],
    cachePage: 1
})

const getters = {
    
}

const mutations = {
    updateCachePage(state, p){

        state.cachePage = p
        
    },
    updateCacheParents(state, p){

        state.cacheParents = p

    }    
}

const actions = {
    async fetchParentsChildrenNumber({ dispatch, state, rootGetters }){

        for (let i = 0; i < state.cacheParents.length; i++) {

            let task = await dispatch('students/fetchStudentsDB', {
                action: 'getStudentsByParent',
                params: {
                    parent: state.cacheParents[i].id,
                    schoolyear: rootGetters['settings/getSettingValue'](Settings.CURRENT_SCHOOLYEAR.NAME)
                }           
            }, { root: true })            

            if (task.success && task.data) state.cacheParents[i].childrenNumber = task.data.length
            else state.cacheParents[i].childrenNumber = 0

        }

    },
    async fetchParentsDB({ dispatch, state }, payload){
        
        let success = false

        let response = null
        let error = null

        try {
            
            response = await axios.post(DATABASE_API_BASE_URL, {
                key: DATABASE_API_KEY,
                context: CONTEXT,
                action: payload.action,
                params: payload.params || null                
            })

            success = response && response.data !== ''

            if (success && payload.action === 'getParents') {
                                
                state.cacheParents = response.data

                await dispatch('fetchParentsChildrenNumber')

            }

        } catch (err) {

            error = err

            success = false

        }
        
        return {            
            success: success,
            failure: !success,
            data: response ? response.data : null,
            error: error
        }

    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}