<template>

  <div class="sidebar-logo-container" :class="{'collapse':collapse}">

    <transition name="sidebarLogoFade">

      <router-link v-if="collapse" key="collapse" class="sidebar-logo-link" to="/">
        <img v-if="collapseLogo" :src="collapseLogo" class="sidebar-logo">
        <h1 v-else class="sidebar-title">
          {{ collapseTitle }}
        </h1>
      </router-link>

      <router-link v-else key="expand" class="sidebar-logo-link" to="/">
        <img v-if="expandLogo" :src="expandLogo" class="sidebar-logo">
        <h1 v-else class="sidebar-title">
          <img v-if="collapseLogo" :src="collapseLogo" class="sidebar-logo" style="margin-right: 0">
        </h1>
      </router-link>

    </transition>

  </div>

</template>

<script>
import logo from "@/assets/images/logo.jpg"

export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      collapseTitle: process.env.VUE_APP_NAME,
      expandTitle: process.env.VUE_APP_NAME,
      collapseLogo: logo,
      expandLogo: null
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}
.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}
.sidebar-logo-container {
  position: relative;
  width: 100%;
  min-height: 50px;
  max-height: 200px;
  background: white;
  text-align: center;
  overflow: hidden;
  & .sidebar-logo-link {
    height: 100%;
    width: 100%;
    & .sidebar-logo {
      padding-top: 9px;
      padding-bottom: 2px;
      width: auto;
      height: 140px;
      vertical-align: middle;
    }
    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #c3a171;
      font-weight: 600;
      line-height: 50px;
      font-size: 21px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }
  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
      height: 50px;
    }
  }
}
</style>
