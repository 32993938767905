import { AppScopes } from '@/utils/enums'

const state = () => ({
    currentAppScope: AppScopes.GLOBAL,
    previousRouteName: null,
    nextRouteName: null,

    currentSchoolyear: null,
    defaultYearCutting: null
})

const getters = {
    
}

const mutations = {
    updateNextRouteName(state, r){

        state.nextRouteName = r
        
    },
    updatePreviousRouteName(state, r){

        state.previousRouteName = r
        
    },
    updateCurrentSchoolyear(state, s){

        state.currentSchoolyear = s

    },
    updateDefaultYearCutting(state, y){

        state.defaultYearCutting = y

    },
    updateCurrentAppScope(state, s){

        state.currentAppScope = s

    }
}

const actions = {

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}