<template>
  <section class="app-main">
    <router-view v-slot="{ Component }">
      <transition name="fade-transform" mode="out-in">
        <div class="component-container"><component :is="Component" :key="key" /></div>
      </transition>
    </router-view>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    key() {
      return this.$route.path
    }
  }
}
</script>

<style scoped>
.app-main {
  /*50 = navbar  */
  min-height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: whitesmoke;
}
.fixed-header+.app-main {
  padding-top: 50px;
}
.component-container {
  padding: 0 25px 15px 25px;
}
</style>

<style lang="scss">
@import "~@/assets/styles/template/index.scss";

// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
