import _ from 'lodash'
import axios from 'axios'

import { DATABASE_API_BASE_URL, DATABASE_API_KEY } from '@/utils/constants'
import { SchoolData } from '@/utils/enums'

const CONTEXT = 'schooldata'

const state = () => ({
    schoolData: []
})

const getters = {
    getSchoolData: (state) => (name) => {

        return state.schoolData.find(s => s.name === name)

    },
    getSchoolDataValue: (state) => (name) => {

        let data = state.schoolData.find(s => s.name === name)
        
        return data ? data.value : ''

    },
}

const mutations = {
    
}

const actions = {
    async initSchoolDataDB({ dispatch, state }){

        await dispatch('fetchSchoolDataDB', { action: 'getSchoolData' })

        _.forIn(SchoolData, async function(value, key) {

            if (state.schoolData && _.isArray(state.schoolData)) {
                
                let index = state.schoolData.findIndex(s => s.name === value.NAME)
                
                if (index === -1) {

                    await dispatch('fetchSchoolDataDB', {
                        action: 'addSchoolData',
                        params: {
                            name: value.NAME,
                            value: value.DEFAULT
                        }
                    })

                }

            } else {
                
                await dispatch('fetchSchoolDataDB', {
                    action: 'addSchoolData',
                    params: {
                        name: value.NAME,
                        value: value.DEFAULT
                    }
                })

            }

        })

        await dispatch('fetchSchoolDataDB', { action: 'getSchoolData' })

    },
    async fetchSchoolDataDB({state}, payload){
        
        let success = false

        let response = null
        let error = null

        try {
            
            response = await axios.post(DATABASE_API_BASE_URL, {
                key: DATABASE_API_KEY,
                context: CONTEXT,
                action: payload.action,
                params: payload.params || null                
            })

            success = response && response.data !== ''

            if (success && payload.action === 'getSchoolData') {
                                
                state.schoolData = response.data

            }

        } catch (err) {

            error = err

            success = false

        }
        
        return {            
            success: success,
            failure: !success,
            data: response ? response.data : null,
            error: error
        }

    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}