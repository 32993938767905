import _ from 'lodash'
import axios from 'axios'

import { DATABASE_API_BASE_URL, DATABASE_API_KEY } from '@/utils/constants'
import { Settings, VarTypes } from '@/utils/enums'

const CONTEXT = 'settings'

const state = () => ({
    settings: []
})

const getters = {
    getSetting: (state) => (name) => {

        return state.settings.find(s => s.name === name)

    },
    getSettingValue: (state) => (name, type = VarTypes.STRING) => {

        let setting = state.settings.find(s => s.name === name)
        
        let value = ''

        switch (type) {
            case VarTypes.NUMBER:

                value = setting && parseInt(setting.value) 
                      ? parseInt(setting.value) 
                      : 0        

                break

            case VarTypes.BOOLEAN:
                
                value = setting && parseInt(setting.value) > 0                

                break
        
            default:

                value = setting && setting.value 
                      ? setting.value 
                      : ''

                break
        }
        
        return value

    },
}

const mutations = {
    
}

const actions = {
    async initSettingsDB({ dispatch, state }){

        await dispatch('fetchSettingsDB', { action: 'getSettings' })

        _.forIn(Settings, async function(value, key) {

            if (state.settings && _.isArray(state.settings)) {
                
                let index = state.settings.findIndex(s => s.name === value.NAME)
                
                if (index === -1) {

                    await dispatch('fetchSettingsDB', {
                        action: 'addSetting',
                        params: {
                            name: value.NAME,
                            value: value.DEFAULT
                        }
                    })

                }

            } else {
                
                await dispatch('fetchSettingsDB', {
                    action: 'addSetting',
                    params: {
                        name: value.NAME,
                        value: value.DEFAULT
                    }
                })

            }

        })

        await dispatch('fetchSettingsDB', { action: 'getSettings' })

    },
    async fetchSettingsDB({ state }, payload){
        
        let success = false

        let response = null
        let error = null

        try {
            
            response = await axios.post(DATABASE_API_BASE_URL, {
                key: DATABASE_API_KEY,
                context: CONTEXT,
                action: payload.action,
                params: payload.params || null                
            })

            success = response && response.data !== ''

            if (success && payload.action === 'getSettings') {
                                
                state.settings = response.data

            }

        } catch (err) {

            error = err

            success = false

        }
        
        return {            
            success: success,
            failure: !success,
            data: response ? response.data : null,
            error: error
        }

    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}